<template>
    <el-row :gutter="10" style="width:80vw">
        <el-col :span="2">
            <el-select v-model="data.type" placeholder="选择变动类型" size="small" width="80">
                <el-option label="全部" value=0></el-option>
                <el-option label="入职" value=1></el-option>
                <el-option label="调岗" value=2></el-option>
                <el-option label="病假" value=3></el-option>
                <el-option label="待岗" value=4></el-option>
                <el-option label="离职" value=5></el-option>
            </el-select>
        </el-col>
        <el-col :span="6">
            <div class="demo-date-picker">
                <el-date-picker v-model="data.date" type="daterange" unlink-panels range-separator="To"
                    start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts" size="small"
                    format="YYYY/MM/DD" />
            </div>
        </el-col>
        <el-col :span="2">
            <div class="demo-date-picker">
                <el-button size="small" :icon="Search" type="success" @click="handleSearch()">搜索</el-button>
                </div>
        </el-col>

    </el-row>
    <el-table :data="tableData" style="width: 100%" size="small" highlight-current-row :height="`calc(100vh - 210px)`"
        v-loading="loading">
        <el-table-column prop="date" label="时间" width="180" />
        <el-table-column prop="name" label="姓名" width="180" />
        <el-table-column prop="type" label="动作">
            <template v-slot="scope">
                <el-tag class="mx-1" effect="dark" size="small" :type="tagType(scope.row.type)">
                    {{ typetranslate(scope.row.type) }}
                </el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="old_job" label="原岗位" />
        <el-table-column prop="new_job" label="新岗位" />
    </el-table>

    <el-pagination background layout="total,prev, pager, next,jumper" :total="pageStruct.total"
        :page-size="pageStruct.pagesize" :current-page="pageStruct.currentpage" @current-change="handleCurrentChange">
    </el-pagination>
</template>

<script setup>
import { Search } from '@element-plus/icons'
import { ref, onBeforeMount, reactive } from 'vue'
import { getjobhistory } from '@/api/services.js'

//分页结构体
let pageStruct = reactive({
    currentpage: 1,
    total: 0,
    pagesize: 20,
})
//分页变化
const handleCurrentChange = (page) => {
    pageStruct.currentpage = page;
    loading.value = true
    getjobhistory(page, data).then(res => {
        tableData.value = res.data.list
        pageStruct.total = res.data.count;
        loading.value = false
    })

}
const handleSearch = () =>{
    loading.value = true
    getjobhistory(1, data).then(res => {
        tableData.value = res.data.list
        pageStruct.total = res.data.count;
        loading.value = false
    })
}

const tagType = (row) => {
    let style = ''
    switch (row) {
        case 1: style = 'success' //入职
            break;
        case 2: style = '' //调岗
            break;
        case 3: style = 'info' //病假
            break;
        case 4: style = 'warning' //待岗
            break;
        case 5: style = 'danger' //离职
            break;

        default: style = ''
            break;
    }
    return style

}
const typetranslate = (row) => {
    let action = ''
    switch (row) {
        case 1: action = '入职' //入职
            break;
        case 2: action = '调岗' //调岗
            break;
        case 3: action = '病假' //病假
            break;
        case 4: action = '待岗' //待岗
            break;
        case 5: action = '离职' //离职
            break;

        default: action = 'error'
            break;
    }
    return action
}
const data = reactive({
    type: '0',
    date: [
        new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        new Date(),
        
    ],
    //  end_time:'2099-12-30'

})

onBeforeMount(() => {
    getjobhistory(1, data).then(res => {
        tableData.value = res.data.list
        pageStruct.total = res.data.count;
        loading.value = false
    })


})


const tableData = ref([])
const loading = ref(true)


const shortcuts = [
    {
        text: '最近一周',
        value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
        },
    },
    {
        text: '最近一个月',
        value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
        },
    },
    {
        text: '最近三个月',
        value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
        },
    },
    {
        text: '最近半年',
        value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6)
            return [start, end]
        },
    },
    {
        text: '最近一年',
        value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 12)
            return [start, end]
        },
    },
]

</script>
<style scoped>



.demo-date-picker {
    display: flex;
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
}
 .el-pagination {
     text-align: center;
 }
</style>